import * as React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const BlueText = ({ children, additionalStyle, onClick }) => {
  const cursorStyle = onClick ? 'cursor-pointer' : 'cursor-text';
  return (
    <>
      <div className={cn('text-blue-600 inline', cursorStyle)} onClick={onClick}>
        <span className={additionalStyle}>{children}</span>
      </div>
    </>
  );
};

BlueText.propTypes = {
  children: PropTypes.node.isRequired,
  additionalStyle: PropTypes.string,
  onClick: PropTypes.func,
};

export default BlueText;
