import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Link from '../../core/a';
import H3 from '../../core/h3';
import Navigate from '../../core/navigate';
import P from '../../core/p';
import SectionContainer from '../../core/section-container';
import BlueText from '../../core/blue-text';

import SnapcardStack from './tech-stack/snapcard-stack';
import PeekabooStack from './tech-stack/peekaboo-stack';
import HoopiStack from './tech-stack/hoopi-stack';
import RubiStack from './tech-stack/rubi-stack';

const Project = ({ id, name, detailsHref, websiteHref, isStackShared, description }) => {
  const [showStack, setStack] = useState('');
  const [animIn, setAnimIn] = useState(true);
  const techStackComponents = {
    Snapcard: SnapcardStack,
    Peekaboo: PeekabooStack,
    Hoopi: HoopiStack,
    Rubi: RubiStack,
  };

  const onClickTechStack = () => {
    if (showStack === name) {
      setAnimIn(false);
      setTimeout(() => {
        setStack('');
      }, 490);
    } else {
      setAnimIn(true);
      setStack(name);
    }
  };

  const TechStack = techStackComponents[showStack];
  // styles
  const paddingLeft = websiteHref ? 'ml-2' : '';
  const animation = animIn ? 'animate-fade-in-down' : 'animate-fade-out-up';
  return (
    <li id={id}>
      <SectionContainer>
        <H3>{name}</H3>
        <div className="flex text-xs">
          {websiteHref ? (
            <div className="text-center">
              <Link href={websiteHref} text="Website" />
            </div>
          ) : (
            <></>
          )}
          {detailsHref ? <Navigate href={detailsHref} text="Details" additionalStyle={paddingLeft} /> : <></>}
          {isStackShared ? (
            <BlueText
              additionalStyle={cn('text-xs select-none', websiteHref || detailsHref ? 'ml-2' : '')}
              onClick={onClickTechStack}
            >
              Tech Stack
            </BlueText>
          ) : (
            <></>
          )}
        </div>
        <P>{description}</P>
        {showStack ? (
          <div className={animation}>
            <TechStack />
          </div>
        ) : (
          <></>
        )}
      </SectionContainer>
    </li>
  );
};

Project.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  detailsHref: PropTypes.string.isRequired,
  websiteHref: PropTypes.string,
  description: PropTypes.string.isRequired,
  isStackShared: PropTypes.bool,
};

export default Project;
