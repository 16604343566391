import * as React from 'react';

import TechStackContainer from './tech-stack-container';
import TechStackItemContainer from './tech-stack-item-container';
import TechnologyItem from '../technology-item';

const HoopiStack = () => (
  <TechStackContainer>
    <TechStackItemContainer stackName="Application & Data" isFirstItem>
      <TechnologyItem technologyName="Next.js" imgTag="reactNative" />
      <TechnologyItem technologyName="Django" imgTag="django" />
      <TechnologyItem technologyName="PostgreSQL" imgTag="postgreSQL" />
      <TechnologyItem technologyName="TailwindCSS" imgTag="tailwindcss" />
      <TechnologyItem technologyName="JavaScript" imgTag="js" />
      <TechnologyItem technologyName="AWS S3" imgTag="awsS3" />
      <TechnologyItem technologyName="AWS EC2" imgTag="awsEC2" />
    </TechStackItemContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Utilities">
        <TechnologyItem technologyName="Swagger" imgTag="swagger" />
        <TechnologyItem technologyName="Postman" imgTag="postman" />
        <TechnologyItem technologyName="Analytics" imgTag="analytics" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="DevOps">
        <TechnologyItem technologyName="VS Code" imgTag="vscode" />
        <TechnologyItem technologyName="PyCharm" imgTag="pyCharm" />
        <TechnologyItem technologyName="Github" imgTag="github" />
        <TechnologyItem technologyName="Git" imgTag="git" />
        <TechnologyItem technologyName="AWS CloudWatch" imgTag="awsCloudWatch" />
        <TechnologyItem technologyName="Yarn" imgTag="yarn" />
        <TechnologyItem technologyName="Docker" imgTag="docker" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Business Tools">
        <TechnologyItem technologyName="Slack" imgTag="slack" />
        <TechnologyItem technologyName="Figma" imgTag="figma" />
        <TechnologyItem technologyName="ClickUp" imgTag="clickUp" />
      </TechStackItemContainer>
    </TechStackContainer>
  </TechStackContainer>
);

export default HoopiStack;
