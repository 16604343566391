import * as React from 'react';

import TechStackContainer from './tech-stack-container';
import TechStackItemContainer from './tech-stack-item-container';
import TechnologyItem from '../technology-item';

const PeekabooStack = () => (
  <TechStackContainer>
    <TechStackItemContainer stackName="Application & Data" isFirstItem>
      <TechnologyItem technologyName="Swift" imgTag="swift" />
      <TechnologyItem technologyName="Django" imgTag="django" />
      <TechnologyItem technologyName="Node.js" imgTag="nodejs" />
      <TechnologyItem technologyName="React Native" imgTag="reactNative" />
      <TechnologyItem technologyName="Mongo DB" imgTag="mongodb" />
      <TechnologyItem technologyName="PostgreSQL" imgTag="postgreSQL" />
      <TechnologyItem technologyName="Elasticsearch" imgTag="elasticSearch" />
      <TechnologyItem technologyName="Ngnix" imgTag="ngnix" />
      <TechnologyItem technologyName="Redis" imgTag="redis" />
      <TechnologyItem technologyName="Django-channels" imgTag="django" />
    </TechStackItemContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Utilities">
        <TechnologyItem technologyName="Swagger" imgTag="swagger" />
        <TechnologyItem technologyName="Postman" imgTag="postman" />
        <TechnologyItem technologyName="Analytics" imgTag="analytics" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="DevOps">
        <TechnologyItem technologyName="VS Code" imgTag="vscode" />
        <TechnologyItem technologyName="PyCharm" imgTag="vscode" />
        <TechnologyItem technologyName="Github" imgTag="github" />
        <TechnologyItem technologyName="Git" imgTag="git" />
        <TechnologyItem technologyName="DigitalOcean" imgTag="digitalOcean" />
        <TechnologyItem technologyName="Yarn" imgTag="yarn" />
        <TechnologyItem technologyName="Docker" imgTag="docker" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Business Tools">
        <TechnologyItem technologyName="Slack" imgTag="slack" />
        <TechnologyItem technologyName="Figma" imgTag="figma" />
        <TechnologyItem technologyName="ClickUp" imgTag="clickUp" />
      </TechStackItemContainer>
    </TechStackContainer>
  </TechStackContainer>
);

export default PeekabooStack;
