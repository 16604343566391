import * as React from 'react';

import TechStackContainer from './tech-stack-container';
import TechStackItemContainer from './tech-stack-item-container';
import TechnologyItem from '../technology-item';

const SnapcardStack = () => (
  <TechStackContainer>
    <TechStackItemContainer stackName="Application & Data" isFirstItem>
      <TechnologyItem technologyName="Gatsby.js" imgTag="gatsby" />
      <TechnologyItem technologyName="GraphQL" imgTag="graphql" />
      <TechnologyItem technologyName="Mongo DB" imgTag="mongodb" />
      <TechnologyItem technologyName="Node JS" imgTag="nodejs" />
      <TechnologyItem technologyName="Tailwind CSS" imgTag="tailwindcss" />
    </TechStackItemContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Utilities">
        <TechnologyItem technologyName="Swagger" imgTag="swagger" />
        <TechnologyItem technologyName="Postman" imgTag="postman" />
        <TechnologyItem technologyName="Analytics" imgTag="analytics" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="DevOps">
        <TechnologyItem technologyName="VS Code" imgTag="vscode" />
        <TechnologyItem technologyName="Github" imgTag="github" />
        <TechnologyItem technologyName="Git" imgTag="git" />
        <TechnologyItem technologyName="AWS Amplify" imgTag="amplify" />
        <TechnologyItem technologyName="Yarn" imgTag="yarn" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Business Tools">
        <TechnologyItem technologyName="Slack" imgTag="slack" />
        <TechnologyItem technologyName="Figma" imgTag="figma" />
        <TechnologyItem technologyName="ClickUp" imgTag="clickUp" />
        <TechnologyItem technologyName="Abstract" imgTag="abstract" />
        <TechnologyItem technologyName="Miro" imgTag="miro" />
      </TechStackItemContainer>
    </TechStackContainer>
  </TechStackContainer>
);

export default SnapcardStack;
