import * as React from 'react';

import TechStackContainer from './tech-stack-container';
import TechStackItemContainer from './tech-stack-item-container';
import TechnologyItem from '../technology-item';

const RubiStack = () => (
  <TechStackContainer>
    <TechStackItemContainer stackName="Application & Data" isFirstItem>
      <TechnologyItem technologyName="Next.js" imgTag="next" />
      <TechnologyItem technologyName="Swift" imgTag="swift" />
      <TechnologyItem technologyName="Firebase" imgTag="firebase" />
      <TechnologyItem technologyName="Django" imgTag="django" />
      <TechnologyItem technologyName="JavaScript" imgTag="js" />
      <TechnologyItem technologyName="App Engine" imgTag="appengine" />
      <TechnologyItem technologyName="Storage" imgTag="storage" />
    </TechStackItemContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Utilities">
        <TechnologyItem technologyName="Swagger" imgTag="swagger" />
        <TechnologyItem technologyName="Postman" imgTag="postman" />
        <TechnologyItem technologyName="Analytics" imgTag="analytics" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="DevOps">
        <TechnologyItem technologyName="VS Code" imgTag="vscode" />
        <TechnologyItem technologyName="PyCharm" imgTag="pyCharm" />
        <TechnologyItem technologyName="Github" imgTag="github" />
        <TechnologyItem technologyName="Git" imgTag="git" />
        <TechnologyItem technologyName="AWS CloudWatch" imgTag="awsCloudWatch" />
        <TechnologyItem technologyName="Yarn" imgTag="yarn" />
        <TechnologyItem technologyName="Docker" imgTag="docker" />
      </TechStackItemContainer>
    </TechStackContainer>
    <TechStackContainer>
      <TechStackItemContainer stackName="Business Tools">
        <TechnologyItem technologyName="Slack" imgTag="slack" />
        <TechnologyItem technologyName="Figma" imgTag="figma" />
        <TechnologyItem technologyName="ClickUp" imgTag="clickUp" />
        <TechnologyItem technologyName="Miro" imgTag="miro" />
      </TechStackItemContainer>
    </TechStackContainer>
  </TechStackContainer>
);

export default RubiStack;
