import React from 'react';

import Layout from 'components/core/layout';
import Seo from 'components/core/seo';
import P from 'components/core/p';
import Project from 'components/pages/projects/project';
import Span from 'components/core/span';
import A from 'components/core/a';

const ProjectsPage = () => (
  <Layout>
    <Seo title="Projects" />
    <ul>
      <Project id="stealth" name="Stealth" description="Developing something new..." />
      <Project
        id="DEUS"
        name="DEUS"
        description="Developed a desktop game based on Ethereum network. Created smart contracts, made mintable NFTs that runs on the Ropsten test network."
      />
      <Project
        isStackShared
        name="Rubi"
        websiteHref="https://www.linkedin.com/company/rubigames"
        description="Rubi is a mobile application that aims to increase the health literacy of individuals between the ages of 9-13 through gamification methods."
      />
      <Project
        isStackShared
        name="Hoopi"
        websiteHref="https://www.hoopi.tv"
        description="Hoopi is a peer to peer video call platform for learning and teaching. You can learn better with 1:1 video calls, choose an instructor, schedule meetings ask anything to your instructor."
      />
      <Project
        isStackShared
        name="Snapcard"
        detailsHref="/projects/snapcard"
        description="Snapcard is a web and mobile application for sharing and storing contact information easily. By using Snapcard users can share their desired contact information with a simple QR code. Snapcard's business model includes B2B solutions for event organizers."
      />
      <Project
        id="Peekaboo"
        isStackShared
        name="Peekaboo"
        description="Peekaboo is a peer to peer video call platform for meeting with new friends. Peekaboo's main purpose is to break the ices between friends by a minute blurry video call and asking them simple and funny questions."
      />
      <Project
        id="EdNet"
        name="EdNet"
        description="EdNet connects teachers with students to remove borders between them. Teachers can create assignments by using EdNet tools, can manage classes, and they can get recommendations from AI. Also when students have an internet connection they automatically download assigned files, they can solve the quizzes from home and when they have a proper connection to the internet the files are being sent to EdNet servers."
      />
    </ul>
  </Layout>
);

export default ProjectsPage;
